//import firebase from 'firebase/app'
import firebase from 'firebase';
import 'firebase/app';




var firebaseConfig = {
  apiKey: "AIzaSyC9vnNFVAG-khgB4pg29ihScYpQUk216vo", // Add API Key
  databaseURL:"https://nairobi-4a23b-default-rtdb.firebaseio.com/" // Add databaseURL
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadRef = firebase.database().ref();

export const userName = prompt("What's your name?");
const urlparams = new URLSearchParams(window.location.search);
const roomId = urlparams.get("id");

if (roomId) {
  firepadRef = firepadRef.child(roomId);
} else {
  firepadRef = firepadRef.push();
  window.history.replaceState(null, "Meet", "?id=" + firepadRef.key);
}

export default firepadRef;
